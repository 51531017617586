<template>
  <v-container>
    <v-row align="center" justify="center" class="mt-3">
      <base-heading class="text-center" :text="$t('resetPassword')" :sub="$t('resetPasswordSub')" />
    </v-row>
    <v-row align="center" justify="center">
      <v-chip class="mb-3" outlined @click="$emit('prev')" v-if="email">
        <v-icon color="primary" left>mdi-account-circle</v-icon>
        <span class="body-2" v-text="email" />
        <v-icon color="black" right>mdi-chevron-down</v-icon>
      </v-chip>
      <v-text-field :label="$t('email')" v-else name="email" autocomplete="email" v-model="currentMail" />
    </v-row>

    <v-row class="mt-8">
      <v-btn to="/auth/signin" color="primary">{{ $t("backToLogin") }}</v-btn>
      <v-spacer />
      <v-btn :loading="isLoading" class="text-capitalize" color="primary" depressed @click="submit">
        {{ $t("resetPassword") }}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
export default Vue.extend({
  data: () => ({ currentMail: "" }),
  computed: {
    ...mapState(["email", "password", "isLoading"]),
  },

  methods: {
    ...mapActions("cognito", ["forgotPassword"]),
    ...mapMutations(["setEmail", "setPassword", "setIsLoading", "setSnackbar"]),
    async submit() {
      this.hasError = false;
      this.setIsLoading(true);
      try {
        if (this.currentMail) {
          this.setEmail(this.currentMail);
        }
        await this.forgotPassword({
          username: this.email || this.currentMail,
        });
        this.$emit("next");
      } catch (err) {
        this.setSnackbar({
          type: "error",
          msg: this.$t(err.code),
        });
      } finally {
        this.setIsLoading(false);
      }
    },
  },
});
</script>

<style></style>
